<template>
<div>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tabTipeProduk == 'mto'}" data-bs-toggle="tab" href="#mto" @click="tabTipeProduk = 'mto'">MTO</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tabTipeProduk == 'rtw'}" data-bs-toggle="tab" href="#rtw" @click="tabTipeProduk = 'rtw'">RTW</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tabTipeProduk == 'display'}" data-bs-toggle="tab" href="#display" @click="tabTipeProduk = 'display'">DISPLAY</a>
                </li>
            </ul>
            <div class="d-flex p-0">
                <div class="card-title">
                    <div class="filter d-flex align-items-center position-relative fs-6 text-gray-600">
                        <div class="filter-item">
                            <span>BRAND</span>
                            <el-select v-model="filter.brand_id" placeholder="Brand" @clear="onFilter" clearable @change="onFilter">
                                <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>KATEGORI</span>
                            <el-select v-model="filter.category_id" placeholder="Kategori" clearable @change="onFilter" @clear="onFilter">
                                <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Vendor</span>
                            <el-select v-model="filter.vendor_id" placeholder="Vendor" clearable @change="onFilter" @clear="onFilter">
                                <el-option v-for="(o, i) in listVendor" :key="i" :value="o.id" :label="o.nama_vendor"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Cari</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @change="onFilter" @clear="onFilter" />
                        </div>
                        <div class="row gx-2">
                            <div class="col">
                                <button class="btn btn-icon btn-light-primary" @click="onFilter()"><i class="fas fa-search"></i></button>
                            </div>
                            <div class="col">
                                <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex align-items-center justify-content-end" data-kt-table-toolbar="base">
                        <router-link :to="'stok_item/history'" class="btn btn-primary">Riwayat</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body tab-content sticky" id="tab_raw_product">
            <div ref="tableRef" class="tab-pane fade table-fixed-header" :class="{active: tabTipeProduk == 'mto', show: tabTipeProduk == 'mto'}" id="mto" role="tabpanel">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Gambar</th>
                            <th>Brand</th>
                            <th>Artikel</th>
                            <th>SKU</th>
                            <th>Kategori</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th>Vendor</th>
                            <th class="text-end w-150px" v-for="(item, i) in tableStore" :key="i">{{ item.name }}</th>
                            <th class="text-end w-150px">Total</th>
                            <th class="text-end w-150px">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td scope="row">{{ i+1 }}</td>
                            <td>
                                <img class="tbl-image" @click="viewImage(item)" :src="img_url_product+item.gambar">
                            </td>
                            <td>{{ item.brand_name }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>    
                            <td>{{ item.vendor }}</td>
                            <td class="text-center" v-for="(data, i) in tableStore" :key="i">
                                <div :title="'Add stock to '+data.name" class="ms-auto w-50px p-1 cursor-pointer bg-gray-200" @click="addStock(item, item.warehouse.find(o => o.id == data.id))">{{ item.warehouse.find(o => o.id == data.id).qty }}</div>
                            </td>
                            <td scope="col" class="text-end">{{ item.total }}</td>
                            <td scope="col" class="text-end">
                                <div class="d-flex justify-content-end">
                                   <button @click="addStock(item, item.warehouse.find(o => o.id == 1))" class="btn btn-primary btn-sm">
                                        <i class="ms-2 fas fa-plus"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="tab-pane fade table-fixed-header" :class="{active: tabTipeProduk == 'rtw', show: tabTipeProduk == 'rtw'}" id="rtw" role="tabpanel">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Gambar</th>
                            <th>Brand</th>
                            <th>Artikel</th>
                            <th>SKU</th>
                            <th>Kategori</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th>Vendor</th>
                            <th class="text-end w-150px" v-for="(item, i) in tableStore" :key="i">{{ item.name }}</th>
                            <th class="text-end w-150px">Total</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td scope="row">{{ i+1 }}</td>
                            <td>
                                <img class="tbl-image" @click="viewImage(item)" :src="img_url_product+item.gambar">
                            </td>
                            <td>{{ item.brand_name }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>    
                            <td>{{ item.vendor }}</td>
                            <td class="text-center" v-for="(data, i) in tableStore" :key="i">
                                <div :title="'Add stock to '+data.name" class="ms-auto w-50px p-1 cursor-pointer bg-gray-200" @click="addStock(item, item.warehouse.find(o => o.id == data.id))">{{ item.warehouse.find(o => o.id == data.id).qty }}</div>
                            </td>
                            <td class="text-end w-150px">{{ item.total }}</td>
                            <td scope="col" class="text-end">
                                <div class="d-flex justify-content-end">
                                   <button @click="addStock(item, item.warehouse.find(o => o.id == 1))" class="btn btn-primary btn-sm">
                                        <i class="ms-2 fas fa-plus"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="tab-pane fade table-fixed-header" :class="{active: tabTipeProduk == 'display', show: tabTipeProduk == 'display'}" id="display" role="tabpanel">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Gambar</th>
                            <th>Brand</th>
                            <th>Artikel</th>
                            <th>SKU</th>
                            <th>Kategori</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th>Vendor</th>
                            <th class="text-end w-150px" v-for="(item, i) in tableStore" :key="i">{{ item.name }}</th>
                            <th class="text-end w-150px">Total</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td scope="row">{{ i+1 }}</td>
                            <td>
                                <img class="tbl-image" @click="viewImage(item)" :src="img_url_product+item.gambar">
                            </td>
                            <td>{{ item.brand_name }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.raw_category }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size }}</td>    
                            <td>{{ item.vendor }}</td>
                            <td class="text-center" v-for="(data, i) in tableStore" :key="i">
                                <div :title="'Add stock to '+data.name" class="ms-auto w-50px p-1 cursor-pointer bg-gray-200" @click="addStock(item, item.warehouse.find(o => o.id == data.id))">{{ item.warehouse.find(o => o.id == data.id).qty }}</div>
                            </td>
                            <td class="text-end w-150px">{{ item.total }}</td>
                            <td scope="col" class="text-end">
                                <div class="d-flex justify-content-end">
                                   <button @click="addStock(item, item.warehouse.find(o => o.id == 1))" class="btn btn-primary btn-sm">
                                        <i class="ms-2 fas fa-plus"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
        </div>
        <div class="text-center my-5">
            <el-pagination 
                background 
                :pager-count="4"
                layout="prev, pager, next" 
                :page-count="pagination.total_page" 
                @next-click="handlePageChange" 
                @prev-click="handlePageChange" 
                @current-change="handlePageChange" 
                @update:current-page="pagination.page"
            />
        </div>
    </div>

    <div class="summary bg-white text-center d-flex justify-content-center" v-if="loadingSummary">
        <div class="loadingio-spinner-pulse-default">
            <div class="ldio-default">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="summary">
            <div class="summary-total">
                <h4 class="mb-5 text-gray-700 text-uppercase">Ringkasan Stok {{ tabTipeProduk }} ({{ tabTipeProduk == 'mto' ? 'Made To Order' : tabTipeProduk == 'rtw' ? 'Ready To Wear' : 'Display' }})</h4>
                <div class="d-flex fs-4 fw-bolder text-gray-700">
                    <div class="d-flex flex-column me-5">
                        <span>Total HPP</span>
                        <span class="text-gray-400">Total Stok</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span><span class="me-2">:</span>{{ listSumByCategory.map(o => o.total_hpp).reduce((prev, next) => prev + next, 0) > 0 ? formatIDR(listSumByCategory.map(o => o.total_hpp).reduce((prev, next) => prev + next, 0), { prefix: 'Rp'}) : 0 }}</span>
                        <span class="text-gray-400"><span class="me-2">:</span>{{ listSumByCategory.map(o => o.total_qty).reduce((prev, next) => prev + next, 0) }}</span>
                    </div>
                </div>  
            </div>
        </div>

        <div class="summary m-0">
            <div class="summary-list-fixed row m-0">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in listSumByCategory" :key="i">
                    <div class="d-flex">
                        <span class="text-uppercase">{{ o.raw_category }}</span>
                        <span class="ms-auto">{{ o.total_hpp ? formatIDR(o.total_hpp, { prefix: 'Rp'}) : 0 }}</span>
                    </div>
                    <div class="d-flex text-gray-400 text-uppercase">
                        <span>Stok</span>
                        <span class="ms-auto text-nowrap">{{ o.total_qty }} PCS</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <el-dialog v-model="modalVisible" :title="tempData.warehouse" custom-class="modal-stok" :close-on-click-modal="true" width="450px">
        <form @submit.prevent="onSubmit">
            <div class="me-n7 pe-7">
                <div class="fv-row mb-7 mt-5 text-gray-800">
                    <div class="mb-1">
                        <span class="badge fs-6 text-uppercase fw-bold w-100" :class="{ 'badge-success': tempData.qty > 10, 'badge-warning': tempData.qty < 10, 'badge-danger': tempData.qty <= 0 }">stok saat ini: {{ tempData.qty }}</span>
                    </div>
                </div>
                <div class="fv-row mb-5">
                    <label class="required fw-bold mb-2">Nama</label>
                    <input type="text" disabled class="form-control" placeholder="Masukkan nama" :value="tempData.name" />
                </div>
                <div class="fv-row mb-5">
                    <label class="required fw-bold mb-2">Artikel</label>
                    <input type="text" disabled class="form-control" placeholder="Masukkan artikel" :value="tempData.artikel" />
                </div>
                <div class="fv-row mb-5">
                    <label class="required fw-bold mb-2">SKU</label>
                    <input type="text" disabled class="form-control" placeholder="Masukkan sku" :value="tempData.sku" />
                </div>
                <div class="fv-row mb-5">
                    <label class="required fw-bold mb-2">Qty</label>
                    <input type="number" class="form-control" placeholder="Masukkan qty" v-model="tempData.so_qty" />
                </div>
                <div class="fv-row mb-5">
                    <label class="required fs-6 fw-bold mb-2">Keterangan</label>
                    <input type="text" class="form-control" placeholder="Masukkan keterangan" v-model="tempData.note" />
                </div>
            </div>
            <div class="mt-4 mb-2 text-center">
                <button type="button" class="btn btn-light me-3" @click="modalVisible = false">Cancel</button>
                <button
                    type="submit"
                    @click="onSubmit"
                    class="btn btn-primary"
                    data-bs-toggle="button"
                    :disabled="loadingButton"
                    :data-kt-indicator="loadingButton ? 'on' : 'off'"
                >
                    <span class="indicator-label"> Submit </span>

                    <span class="indicator-progress">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </form>
    </el-dialog>

    <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="5vh">
        <inner-image-zoom :src="getZoomImage.src"/>
    </el-dialog>
</div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
//import { InfoFilled } from '@element-plus/icons-vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";


export default defineComponent({
    components: { 
        //Datatable,
        InnerImageZoom,
    },
    setup() {
        const store = useStore()
        const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const loadingTable = ref(true);
        const route = useRoute()
        const router = useRouter()
        
        const loadingButton = ref(false);
        const loadingSummary = ref(false)
        const disabledButton = ref(true)
        const modalVisible = ref(false)
        const modalTitle = ref('')
        const search = ref(null)

        const tableRef = ref(null)

        const tableData = reactive([])
        const totalRecordCount = ref(0)
        
        const tableStore = reactive([])

        const tabTipeProduk = ref('mto')

        const filterActive = ref(false)
        const filter = reactive({
            sku: null,
            artikel: null,
            brand_id: null,
            vendor_id: null,
            category_id: null,
            search: null,
            tipe_produk: null,
        })

        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = data.name
            getZoomImage.src = img_url_product.value+data.gambar
        }

        const tempData = reactive({
            raw_product_id: null,
            warehouse_id: 1,
            qty: null,
            total_qty: null,
            so_qty: null
        })

        const listBrand = computed(() => store.getters.getListBrand)
        const listCategory = computed(() => store.getters.getListCategory)
        const listColor = computed(() => store.getters.getListColor)
        const listVendor = computed(() => store.getters.getListVendor)
        const listWarehouse = computed(() => store.getters.getListWarehouse)

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData()
            loadingTable.value = false
        }

        const addStock = (data, warehouse) => {
            modalVisible.value = true
            tempData.raw_product_id = data.raw_product_id
            tempData.name = data.raw_category + ' ' + data.color + ' ' + data.size
            tempData.artikel = data.artikel
            tempData.sku = data.sku
            tempData.qty = warehouse.qty
            tempData.warehouse = warehouse.name
            tempData.warehouse_id = warehouse.id
            tempData.total_qty = data.total
            tempData.color_id = data.color_id
            tempData.note = "";

            rowScrollPosition.value = tableRef.value.scrollTop
            htmlScrollPosition.value = document.querySelector('html').scrollTop
        }

        const rowIndex = ref(null)
        const rowData = ref(null)
        const rowScrollPosition = ref(null)
        const htmlScrollPosition = ref(null)

        const currentRowView = (index) => {
            tableRef.value.scrollTop = rowScrollPosition.value
            document.querySelector('html').scrollTop = htmlScrollPosition.value
        }

        const getData = async () => {
            try {
                loadingTable.value = true
                tableData.splice(0)

                let page = filter.page ? '?page=' + filter.page : '?page=1';
                let category = filter.category_id ? '&category=' + filter.category_id : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let vendor = filter.vendor_id ? '&vendor=' + filter.vendor_id : '';
                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("inventory/stok_item/" + tabTipeProduk.value + page +  category + brand + vendor + search )
                .then( async ({ data }) => {
                    await tableData.splice(0)
                    pagination.value = data.data.pagination
                    
                    Object.assign(tableData, data.data.data)

                    //await summaryStokItem()
                })
                .catch(({ response }) => {
                    console.log('Error getting stok item');
                });
                
                // let cek = Object.keys(Object.assign({}, ...tableData)).filter((o, i) => {
                //     return o.includes('_store')
                // })

                Object.assign(tableStore, tableData[0].warehouse)

            } catch(e) {
                console.log(e)
            }
                    
            loadingTable.value = false
        }

        const listSumByCategory = reactive([])

        const summaryStokItem = async() => {
            try {
                loadingSummary.value = true

                listSumByCategory.splice(0)

                await ApiService.get("inventory/summary_stok_item/"+tabTipeProduk.value)
                .then(({ data }) => {
                    Object.assign(listSumByCategory, data)
                })
                .catch(({ response }) => {
                    console.log('Error getting sumarry stok item');
                });
            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingSummary.value = false
        }
                
        const onFilter = async () => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            store.dispatch(Actions.ADD_FILTER, filter);

            filterActive.value = false

            loadingTable.value = true

            await getData()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {
          if(Object.values(filter).every(o => o == null || o.length == 0)) {
            //return
          }
          
          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false


          await getData()
        }

        const onSubmit = async () => {
            let setData = {
                raw_product_id: tempData.raw_product_id,
                warehouse_id: tempData.warehouse_id,
                qty: tempData.so_qty,
                note: tempData.note,
            }

            loadingButton.value = true

            await ApiService.post("inventory/create_so_item", setData)
            .then(async({ data }) => {
                loadingButton.value = false
                modalVisible.value = false

                Swal.fire({
                    title: data.messages,
                    icon: "success",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })

                tempData.so_qty = null
                tempData.note = ''

                await getData()
                currentRowView(rowIndex.value)

                loadingButton.value = false
            })
            .catch(({ response }) => {
                Swal.fire({
                    text: "Failed Create New Stock Opname",
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                loadingButton.value = false
            });

            modalVisible.value = false
        }

        const stateFilter = () => {
          try {
            const getCurrentRoutePath = route.path.split('/')[1]
            // console.log(getCurrentRoutePath)
            // console.log(router.options.history.state.back)
            if(getCurrentRoutePath && router.options.history.state.back.includes(getCurrentRoutePath)) {
              Object.assign(filter, store.getters.getFilter);
            }
          } catch(e) {
            console.error(e)
          }
        }

        onBeforeMount(async() => {
            getData()
            summaryStokItem()

            store.dispatch(Actions.LIST_BRAND);
            store.dispatch(Actions.LIST_CATEGORY);
            store.dispatch(Actions.LIST_VENDOR);
        })

        onMounted( async() => {
            setCurrentPageTitle("Inventory - Stok Item");
            setCurrentPageBreadcrumbs("Inventory - Stok Item", ["Stok Item"]);

            stateFilter()

            if(filter.tipe_produk) {
              tabTipeProduk.value = filter.tipe_produk.toLowerCase()
            }
        })

        watch(() => tabTipeProduk.value, (val) => {
          if(val) {

            if(val.toLowerCase() == 'display') {
                img_url_product.value = process.env.VUE_APP_IMAGE_DESAINS;
            } else {
                img_url_product.value = process.env.VUE_APP_IMAGE_PRODUCTS;
            }

            filter.tipe_produk = val
            store.dispatch(Actions.ADD_FILTER, filter);
            getData()
          }
        })

        // watch(() => tempData.so_qty, (val) => {
        //   val || val > 0 ? disabledButton.value = false : disabledButton.value = true
        // })

      watch(() => tempData.so_qty, (val) => {
            val || val > 0 ? disabledButton.value = false : disabledButton.value = true
        })

        return {
            route, router,
            loadingTable, img_url_product, modalVisible, modalTitle, disabledButton,
            handlePageChange, pagination,
            tableData, getData, tableStore, tableRef,
            viewImage, getZoomImage,
            addStock, tempData, summaryStokItem, listSumByCategory,
            listCategory, listBrand, listVendor,
            tabTipeProduk, onFilter, resetFilter, filter,
            onSubmit, loadingButton, loadingSummary,
            formatIDR,
        }
    }
})
</script>